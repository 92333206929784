/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Media containers */

/* Youtube/Vimeo videos */
.bandcamp-container,
.video-container {
  position: relative;
  padding-bottom: 56.25%; // Default 16:9
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.four-by-three {
  padding-bottom: 75%; // 4:3
}
