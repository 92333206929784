.figure-left {
  float: left;
  width: 40%;
  margin-right: 5%;
}

.text-right {
  text-align: right;
}

.border-top {
  border-top: solid 5px $grey-color-dark;
}
