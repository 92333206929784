/**
 * Utilities
 */
.mt-0 {
  margin-top: 0 * $rhythm;
}
.mt-1 {
  margin-top: 1 * $rhythm;
}
.mt-2 {
  margin-top: 2 * $rhythm;
}
.mt-3 {
  margin-top: 3 * $rhythm;
}
.mt-4 {
  margin-top: 4 * $rhythm;
}

.mb-0 {
  margin-bottom: 0 * $rhythm;
}
.mb-1 {
  margin-bottom: 1 * $rhythm;
}
.mb-2 {
  margin-bottom: 2 * $rhythm;
}
.mb-3 {
  margin-bottom: 3 * $rhythm;
}
.mb-4 {
  margin-bottom: 4 * $rhythm;
}

/**
 * Wrapper
 */
.wrapper {
  max-width: $content-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;
}

/**
 * Site header
 */
.site-header {
  height: 120px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  width: 96px;
  display: block;
  float: left;
}

.site-nav {
  float: right;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit 0;
}

.footer-heading {
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @extend %clearfix;
}

@media (min-width: 800px) {
  .footer-col-left {
    width: 40%;
  }

  .footer-col-right {
    float: right;
    width: 55%;
    margin-left: 5%;
  }
}


/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  color: $grey-color;
}

.post-link {
  display: block;
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-content {
  margin-bottom: $spacing-unit;

  @media (min-width: 800px) {
    > * {
      width: 55%
    }

    > figure {
      float: right;
      width: $right-column-width;
      margin-left: 5%;
    }
  }
}

.grid {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2 * $rhythm;

  > * {

  }
}
