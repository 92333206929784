@import url("https://fonts.googleapis.com/css2?family=Gentium+Plus:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

@mixin body-text {
  font-family: "Gentium Plus", serif;
  font-size: $font-size-body;
  line-height: $line-height-body;
}

@mixin header-text {
  font-family: "Roboto Condensed", sans-serif;
}

body {
  @include body-text;
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
}

p, blockquote, ul, ol,
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $rhythm;
}

h1, h2, h3, h4, h5, h6 {
  @include header-text;
  font-weight: normal;
  margin-top: 2 * $rhythm;
}

li {
  margin-top: 0;
  margin-bottom: 0;
}

.text-sans {
  @include header-text;
}
