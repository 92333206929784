$rhythm: 13px;

$font-size-body: 18px;
$line-height-body: 2 * $rhythm;

$font-size-1: 24px;
$line-height-1: 3 * $rhythm;

$font-size-2: 20px;
$line-height-2: 3 * $rhythm;

.pt-1 {
  padding-top: 1 * $rhythm;
}

.pt-2 {
  padding-top: 2 * $rhythm;
}

.pb-1 {
  padding-bottom: 1 * $rhythm;
}

.mt-1 {
  margin-top: 1 * $rhythm;
}

.mt-2 {
  margin-top: 2 * $rhythm;
}

.mt-3 {
  margin-top: 3 * $rhythm;
}
