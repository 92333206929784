@charset "utf-8";

// Define defaults for each variable.

$spacing-unit:     30px !default;

$black-color:      #000000 !default;
$text-color:       #CCCCCC !default;
$background-color: #434B5F !default;
$brand-color:      #E2D2A9 !default;

$grey-color:       #333947 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  #333948 !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1000px !default;

$right-column-width: 55%;

// Import partials.
@import
  "minima/sizing",
  "minima/base",
  "minima/colors",
  "minima/utilities",
  "minima/layout",
  "minima/syntax-highlighting",
  "minima/typography",
  "minima/media"
;
